.main{
    width: 80%;
    margin: 0px auto;
    
}

@media screen and (max-width: 600px) {
    .main{
        width: 95%;
        margin: 0px 10px;
    }
}