.card {
    padding: 10px;
    background-color: #fdf1f1;
}
.card-centent{
    color :rgb(77, 29, 29);
    border: none;
    outline: none;
    padding: 5px;
    border-radius: 6px;
}

.card-title {
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .card {
        margin: 0px; 
    }
}
    

