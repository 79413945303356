/* PopupForm.css */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure the popup is on top of everything else */
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin: 2px 10px;
  width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10000; /* Higher than overlay to ensure it's above the dark background */
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.form-actions button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-actions button:first-child {
  background-color: #28a745;
  color: white;
}

.form-actions button:last-child {
  background-color: #dc3545;
  color: white;
}



@media screen and (max-width: 600px) {
  .form-group textarea {
      height: 250px;
  }
}