.SignUp {
    border-radius: 10px;
    width: 70%;
    margin: 50px auto;
    background: #f8f8f8;
    padding: 10px;
}
p{
    margin: 10px auto;
}
.form-group {
    padding: 1px 10px;
}

.button-signUp {
    margin: 10px 50px;
    width: 30%;
    color: white;
    background: red;
    padding: 6px;
    outline: none;
    border: none;
    border-radius: 4px;
}
.button-signUp:hover {
    background: #fc2e2e;
}

@media screen and (max-width: 600px) {
    .SignUp{
        width: 95%;
        margin: 50px 10px;
    }
}